import React, {Component} from "react";

import 'react-toastify/dist/ReactToastify.css';
import {
    apiGetGlobalSeasonConfig,
    apiGetLeaderboard,
    apiGetUserInfoMany,
} from "./api/requests";
import {Loading} from "./common/utility/loading";
import {delay} from "./common/utility/delay";
import CountdownLabel from "./common/countdownLabel";
import IconTitleValue from "./common/iconTitleValue";
import heroes from "./heroes.json";
import {Link} from "react-router-dom";

class SeasonTopPage extends Component {
    constructor(props) {
        super(props);

        const userId = localStorage.getItem('userId');

        this.state = {
            loading: true,
            userId: userId,
            noSeason: false,
            isHero: this.props.search.get("is_hero")
        }
        this.loadSeasonTop = this.loadSeasonTop.bind(this);
    }

    componentDidMount() {
        this.loadSeasonTop()
    }

    async loadSeasonTop() {
        await apiGetGlobalSeasonConfig({

        }).then(async (response) => {
            this.setState({
                config: response.data.grand_season.config,

            })
            let isHero = true
            if (this.state.isHero === undefined || this.state.isHero === null) {
                isHero = false
            }
            await apiGetLeaderboard(
                1, 50, isHero
            ).then(async (response) => {
                console.log(response)
                await delay(1000);

                if(response.leaderboard.users === null) {
                    this.setState({
                        players: [],
                        loading: false,
                    })
                    return
                }

                let players = response.leaderboard.users;
                const ids = players.map(x => {
                    return x.uid
                });

                let infos
                if(this.state.isHero !== null) {
                    const heroesOn = heroes.filter(hero => hero.playable );
                    infos = heroesOn.map((x, idx) => { return {uid: x.id, username: x.heroName, avatar: x.imageUrl} });
                    infos = {users: infos}
                } else {
                    infos = await apiGetUserInfoMany(ids);
                }

                players = players.map((x, idx) => {
                    return {...x, ...infos.users.find(y => y.uid == x.uid)}
                });


                this.setState({
                    players: players,
                    loading: false,
                })
            }).catch(e => {
                window.alerts.alert("internal error. please try again later")
            })
        }).catch(e => {
            window.alerts.alert("We are currently doing an update, please try refreshing in 5 minutes!")
            this.setState({
                noSeason: true,
                loading: false,
            })
        })
    }

    render() {
            if(this.state.noSeason === true) {
                return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                        <h3 className={"notransform-h3"}>We are currently doing an update, please try refreshing in 5 minutes!</h3>
                    </div>
                </div>
            }

            let isHero = true
            if (this.state.isHero === undefined || this.state.isHero === null) {
                isHero = false
            }

            if(this.state.loading === true && isHero === false) {
                return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                        <h1 className={"notransform-h1"}>Loading data...</h1>
                    </div>
                    <Loading></Loading>
                    <div>
                        <p>Anonymous logins will not participate in the reward distribution!</p>
                        <p>You can change your nick on the leaderboard and start a new game in the PROFILE tab.</p>
                    </div>
                </div>

            }

        if(this.state.loading === true && isHero === true) {
            return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                    <h1 className={"notransform-h1"}>Loading data...</h1>
                </div>
                <Loading></Loading>
                <div>
                    <p>The hero who finishes last in the season is eliminated from Force Prime.</p>
                </div>
            </div>

        }

        let seasonDataPanel = <>
            <div className="pt-3 pb-3">
                <div className="d-flex ">
                    <IconTitleValue
                        icon="prizePool.svg"
                        title="Players prize">
                        {this.state.config.prize}
                    </IconTitleValue>
                    <IconTitleValue
                        icon="seasonEnd.svg"
                        title="Season ends in">
                        <CountdownLabel targetDate={new Date(this.state.config.date_end)}/>
                    </IconTitleValue>
                </div>
            </div>
        </>
        if(isHero === true) {
            seasonDataPanel = <>
                <div className="pt-3 pb-3">
                    <div className="d-flex ">
                        <IconTitleValue
                            icon="seasonEnd.svg"
                            title="Season ends in">
                            <CountdownLabel targetDate={new Date(this.state.config.date_end)}/>
                        </IconTitleValue>
                    </div>
                </div>
            </>
        }

        let seasonText = <>
            <div>
                <p>Season rewards:</p>
                <ul>
                    <li>1st place - Force Prime NFT and 700 STRK tokens</li>
                    <li>2nd place - 300 STRK tokens</li>
                    <li>3rd place - 200 STRK tokens</li>
                    <li>4th and 5th places - 100 STRK tokens</li>
                    <li>20 random wallets having more then 250 Season Points will each receive 30 STRK tokens
                    </li>
                </ul>
                <p>Anonymous logins will not participate in the reward distribution!</p>

                <p>You can change your nick on the leaderboard and start a new game in the PROFILE tab.</p>
            </div>
        </>
        if (isHero === true) {
            seasonText = <>
                <div>
                    <p>The hero who finishes last in the season is eliminated from Force Prime.</p>
                </div>
                </>
        }

        let playerField = "Player"
        let pageHeader = "Top-50 Players"
        if (this.state.isHero !== null) {
            playerField = "Hero"
            pageHeader = "Heroes Leaderboard"
        }

        return (
            <>
                <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="d-flex flex-row align-items-center">
                        <h1 className={"notransform-h1"}>{pageHeader}</h1>
                    </div>
                    {seasonDataPanel}
                    {seasonText}
                    <table role="table" className="leaderboard-table" style={{minWidth: "0px"}}>
                        <thead>
                        <tr role="row" className={"table-row"}>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell"}>
                                <div className="default-tile-header iconTitleTile-title">Place</div>
                            </th>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell-th-username"}>
                                <div className="default-tile-header iconTitleTile-title">{playerField}</div>
                            </th>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell"}>
                                <div className="default-tile-header iconTitleTile-title">Season points</div>
                            </th>
                        </tr>
                        </thead>
                        <tbody role="rowgroup">

                        {this.state.players.map((v, k) => {
                            console.log(v, k, this.state.userId)
                            const selfClass = v.uid === this.state.userId ? " table-self-row" : ""
                            return <>
                                <tr role="row" className={"d-flex flex-row align-content-center table-row " + selfClass}>
                                    <td role="cell"
                                        className={"table-cell"}>
                                        <div className="leaderboard-table-place">
                                            <div><span>{v.place}</span></div>
                                        </div>
                                    </td>
                                    <td role="cell"
                                        className={"table-cell-username"}>{this.state.isHero !== null ? <img src={v.avatar}
                                                                               className="hero-element-image-table rounded-1 me-1"
                                                                               alt={v.username}/> : <></>} {v.username}
                                    </td>
                                    <td role="cell"
                                        className={"table-cell d-flex flex-row justify-content-between"}
                                        style={{alignSelf: "start"}}> {v.points} {this.state.isHero ? <Link className={"ms-auto btn btn-primary"} to={"/hero-page?hero_id="+v.uid}>info</Link> : <></> }
                                    </td>
                                </tr>
                            </>
                        })}


                        </tbody>
                    </table>

                </div>
            </>
        )
    }
}

export default SeasonTopPage;
